import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { Mxw8xDeviceBehaviorPluginBase } from './mxw8x.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxw8xDesignerDeviceBehaviorPlugin extends Mxw8xDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
