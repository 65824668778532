import { TranslocoService } from '@jsverse/transloco';

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `MxwapxdDeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export abstract class MxwapxdDeviceBehaviorPluginBase implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXWAPXD2];

	constructor(private readonly transloco: TranslocoService) {}

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXWAPXD2:
				return {
					lightsBackground: 'device-mxwn-apxd2-light.svg',
					live: 'device-mxwn-apxd2-light.svg',
					virtual: 'device-mxwn-apxd2-light.svg',
					liveBack: 'device-mxwn-apxd2-light.svg',
					virtualBack: 'device-mxwn-apxd2-light.svg'
				};
			default:
				return undefined;
		}
	}
}
