import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { Mxa901DeviceBehaviorPluginBase } from './mxa901.device-behavior';

@Injectable({ providedIn: 'root' })
export class Mxa901DesignerDeviceBehaviorPlugin extends Mxa901DeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
