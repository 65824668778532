import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { P300DeviceBehaviorPluginBase } from './p300.device-behavior';

@Injectable({ providedIn: 'root' })
export class P300DesignerDeviceBehaviorPlugin extends P300DeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
