import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { ImxrDeviceBehaviorPluginBase } from './imxr.device-behavior';

@Injectable({ providedIn: 'root' })
export class ImxrDesignerDeviceBehaviorPlugin extends ImxrDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
