import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { AdtqDeviceBehaviorPluginBase } from './adtq.device-behavior';

@Injectable({ providedIn: 'root' })
export class AdtqDesignerDeviceBehaviorPlugin extends AdtqDeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
