import { NgModule } from '@angular/core';

import { Ad600DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/ad600';
import { AdtdDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/adtd';
import { AdtqDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/adtq';
import { AniusbDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/aniusb';
import { ImxrDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/imxr';
import { McrDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mcr';
import { Mxa710DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxa710';
import { Mxa901DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxa901';
import { Mxa902DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxa902';
import { Mxa920DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxa920';
import { Mxn5cDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxn5c';
import { Mxw1xDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxw1x';
import { Mxw2xDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxw2x';
import { Mxw6xDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxw6x';
import { Mxw8xDesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxw8x';
import { Mxwapxd2DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/mxwapxd2';
import { P300DesignerDeviceBehaviorPlugin } from '@shure/cloud/shared/device-behavior/devices/p300';
import {
	DeviceBehaviorPluginsModule,
	DeviceImageBehaviorPluginService
} from '@shure/shared/angular/device-behavior/core';
import { DeviceImageProvider } from '@shure/shared/angular/ui/components/device-image';

@NgModule({
	imports: [
		DeviceBehaviorPluginsModule.forRoot([
			Ad600DesignerDeviceBehaviorPlugin,
			AdtdDesignerDeviceBehaviorPlugin,
			AdtqDesignerDeviceBehaviorPlugin,
			AniusbDesignerDeviceBehaviorPlugin,
			ImxrDesignerDeviceBehaviorPlugin,
			McrDesignerDeviceBehaviorPlugin,
			Mxa710DesignerDeviceBehaviorPlugin,
			Mxa901DesignerDeviceBehaviorPlugin,
			Mxa902DesignerDeviceBehaviorPlugin,
			Mxa920DesignerDeviceBehaviorPlugin,
			Mxn5cDesignerDeviceBehaviorPlugin,
			Mxw1xDesignerDeviceBehaviorPlugin,
			Mxw2xDesignerDeviceBehaviorPlugin,
			Mxw6xDesignerDeviceBehaviorPlugin,
			Mxw8xDesignerDeviceBehaviorPlugin,
			Mxwapxd2DesignerDeviceBehaviorPlugin,
			P300DesignerDeviceBehaviorPlugin
		])
	],
	providers: [
		{
			provide: DeviceImageProvider,
			useExisting: DeviceImageBehaviorPluginService
		}
	]
})
export class CloudDeviceBehaviorPluginsModule {}
