import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { Ad600DeviceBehaviorPluginBase } from './ad600.device-behavior';

@Injectable({ providedIn: 'root' })
export class Ad600DesignerDeviceBehaviorPlugin extends Ad600DeviceBehaviorPluginBase {
	constructor(transloco: TranslocoService) {
		super(transloco);
	}
}
